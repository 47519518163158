import {createAsyncThunk} from "@reduxjs/toolkit";
import {useHttp} from "../../hooks/http.hook";


export const fetchNewsInfo = createAsyncThunk(
    'newsInfo/fetchNewsInfo',
    async (query) => {
        const {request} = useHttp();
        const dataLang = localStorage.getItem('dataLang');
        const shortNameParam = dataLang ? `?lang=${JSON.parse(dataLang).shortName}` : '';
        let url = `api/news/${window.env.PROJECT_ID}/${shortNameParam}`;
        let searchString = ''

        if (query.search) {
            const parts = query.search.split(' ')

            parts.forEach(part => {
                if (!part) {
                    return
                }

                if (searchString) {
                    searchString += ' OR '
                }

                searchString += `contains(tolower(shortContent), '${part.toLowerCase()}') OR contains(tolower(titleContent), '${part.toLowerCase()}')`
            })
        }

        if (searchString) {
            url += `&filter=${searchString}`
        }

        if (query.hasOwnProperty('categoryId')) {
            url += `&categoryId=${query.categoryId}`;
        }
        if (query.hasOwnProperty('top')) {
            url += `&top=${query.top}`;
        }
        if (query.hasOwnProperty('skip')) {
            url += `&skip=${query.skip}`;
        }

        const {json} = await request(url, 'GET');
        return await new Promise((resolve) => resolve(json));
    }
);
