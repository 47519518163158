import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    term: '',
    searchByTyping: false
}

const manualChangeCategorySlice = createSlice({
        name: 'manualChangeCategory',
        initialState,
        reducers: {
            updateCategory: (state, action) => {
                state.term = action.payload
            },
            searchByTyping: (state, action) => {
                state.searchByTyping = action.payload
            }
        }
    }
);

const {actions, reducer} = manualChangeCategorySlice;

export default reducer;

export const {
    updateCategory,
    searchByTyping
} = actions;

