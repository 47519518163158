import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    form: {}
}

const formDataSlice = createSlice({
        name: 'formData',
        initialState,
        reducers: {
            updateForm: (state, action) => {
                state.form = action.payload;
            },
        }
    }
);

const {actions, reducer} = formDataSlice;

export default reducer;

export const {updateForm} = actions;
